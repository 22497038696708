.rulesModal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    height: 100svh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
  }
  .rulesModal.active {
    opacity: 1;
    pointer-events: all;
  }
  
  .rulesModalContent {
    max-width: 320px;
    max-height: 80vh;
    width: 100%;
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    transition: all linear 0.2s;
    transform: scale(0);
    text-align: left;
  }
  .rulesModalContent.active {
    transform: scale(1);
  }
  
  .rulesModalContent input:focus {
    display: inline-block;
    border-color: #c21818;
    outline: 0 none;
  }
  