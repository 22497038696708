.uploadBookModal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    height: 100svh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
}
.uploadBookModal.active {
    opacity: 1;
    pointer-events: all;
}
  
.uploadBookModalContent {
    min-width: 320px;
    max-height: 80vh;
    width: 50vw;
    width: 100%;
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    transition: all linear 0.2s;
    transform: scale(0);
    text-align: left;
}
.uploadBookModalContent.active {
    transform: scale(1);
}
  
.uploadBookModalContent input:focus {
    display: inline-block;
    border-color: #c21818;
    outline: 0 none;
}
  
.title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}
  
.input {
    width: 100%;
    height: 1.6em;
    border-radius: 5px;
    border: 1px solid rgb(172, 172, 172);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    transition: all linear 0.3s;
    padding: 0;
    margin-top: 5px;
}
.invalidInput {
    border: 2px solid rgb(255, 0, 0);
}

.wrapper {
    display: flex;
}

.mainForm {
    display: flex;
    justify-content: space-between;
}

.mainForm > div:first-child {
    width: 90%;
    margin-right: 30px;
}

.uploadButton {
    height: 41px;
    border-radius: 5px;
    border: 1px #000 solid;
    cursor: pointer;
}
.disabledButton {
    height: 41px;
    border-radius: 5px;
    border: 1px #000 solid;
    pointer-events: none;
    background-color: gray;
}

.errorText {
  color: rgb(255, 0, 0);
  font-weight: 700;
  font-size: 12px;
  padding: 6px 0;
}