.editButton {
    margin-top: 30px;
    font-size: 40px;
    width: 100%;
    border: 1px solid black;
    cursor: pointer;
    transition: all linear .2s;
  }
  .editButton:hover {
    background-color: aliceblue;
  }
  