.bookPage {
  /* height: 90vh; */
  min-height: calc(100svh - 96px - 70px);
  position: relative;
}
.bookPage:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 240px;
  background-color: #1c1c1c;
  z-index: -1;
}

.content {
  padding: 70px 70px 10px;
}

.mainContent {
  display: flex;
  text-align: right;
}

.image {
  width: 30%;
}
.image img {
  width: 100%;
  border: 1px solid white;
}

.text {
  width: 70%;
}
.text > p {
  font-family: "Tinos";
  padding-left: 50px;
  padding-top: 50px;
  font-size: 18px;
  line-height: 1.3;

/* recheck on all tablets !!!*/
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}
.description > .showDescriptionButton {
  font-family: "Tinos";
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
}

.blackBand {
  height: 150px;
  color: #fff;
  font-weight: 400;
  font-size: 37px;
  padding-left: 30px;
}

.title {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 20px;
}

.author {
  font-size: 30px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.adminButtons {
  margin-top: 10px;
}
.buttons button {
  font-family: "Playfair Display";
  font-weight: 400;
  width: 30%;
  font-size: 24px;
  padding: 10px 0;
  border: none;
  transition: all linear 0.2s;
  cursor: pointer;
}
.buttons button:first-child {
  background-color: #1c1c1c;
  color: #fff;
}
.buttons button:first-child:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.buttons button:last-child {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.buttons button:last-child:hover {
  background-color: #000;
  color: #fff;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.description {
  white-space: pre-line;
}

.deleteImage {
  font-size: 25px;
  width: 100%;
  cursor: pointer;
  border: 2px solid black;
  transition: all linear .2s;
}
.deleteImage:hover {
  color:white;
  background-color: black;
}
.uploadImage {
  background: white;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  transition: all linear .2s;
}
.uploadImage:hover {
  opacity: .7;
}
.uploadImageInput {
  color: white;
  width: 100%;
}

@media (max-width: 1500px) {
  .bookPage:before {
    height: 220px;
  }
  .content {
    padding: 40px 100px 10px;
  }
  .bookPage {
    min-height: calc(100svh - 66px - 70px);
  }
}
@media (max-width: 1350px) {
  .loader {
    transform: translate(-50%, -30%) scale(.8)
  }
  .title {
    font-size: 34px;
  }
  .author {
    font-size: 30px;
  }
}
@media (max-width: 1199px) {
  .content {
    padding: 40px 10px 10px;
  }
  .title {
    font-size: 30px;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
  .author {
    font-size: 26px;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }
}
@media (max-width: 991px) {
  .bookPage:before {
    height: 190px;
  }
  .image {
    width: 35%;
  }
  .text {
    width: 65%;
  }
  .text > p {
    font-size: 16px;
    padding-top: 30px;
    padding-left: 30px;
  }
  .buttons button {
    font-size: 20px;
    width: 35%;
  }
}
@media (max-width: 767px) {
    .mainContent {
        display: block;
    }
    .blackBand {
        width: 100%;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        padding-left: 0;
    }
    .title {
      font-size: 28px;
    }
    .author {
      font-size: 24px;
    }
    .image {
        margin: 103px auto 0;
        width: 30%;
    }
    .text {
        width: 100%;
    }
    .text > p {
        display: block;
        width: 100%;
        text-align: center;
        padding: 30px 0 0 0;
        font-size: 18px;
    }
    .buttons {
        position: absolute;
        width: 90%;
        top: 180px;
        left: 50%;
        transform: translateX(-50%)
    }
    .buttons > button {
        width: 30%;
    }
    .adminButtons {
        margin-top: 100px;
    }
}
@media (max-width: 600px) { 
    .bookPage:before {
        height: 350px;
    }
    .title {
      font-size: 24px;
      
    }
    .author {
      font-size: 20px;
    }
    .image {
        width: unset;
        height: 284px;
        text-align: center;
    }
    .image img{
        height: 100%;
        width: unset;
    }
    .buttons {
        display: block;
        top: 410px;
    }
    .buttons button{
        display: block;
        width: 60%;
        margin: 0 auto 10px;
        height: 49px;
    }
    .adminButtons {
        margin-top: 160px;
    }
    .text > p {
        padding: 140px 0 0 0;
        font-size: 17px;
    }
}