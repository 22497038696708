.footer {
    background-color: #fef1f3;
}

.footerWrapper {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    height: 70px;
    align-items: center;
}
.footerWrapper > div:first-child {
    cursor: pointer;
}

.countryIcons {
    display: flex;
    align-items: center;
}

.emojiFlag {
    cursor: pointer;
    padding-left: 6px;
    transition: all linear .2s;
    vertical-align: middle;
}
.commonFlag {
    cursor: pointer;
    width: 35px;
    margin-left: 8px;
    margin-top: 6px;
    transition: all linear .2s;
    box-shadow: 0px -5px 9px 3px rgb(99 99 99 / 20%);
}
.emojiFlag:hover, .commonFlag:hover {
    transform: scale(1.1);
}

@media (max-width: 991px) {
    .footer.AM {
       font-size: 13px;
    }
    .footer.RU {
        font-size: 14.5px;
     }
}
@media (max-width: 666px) {
    .footerWrapper {
        display: block;
        margin: 0 auto;
        text-align: center;
        font-size: 14px;
        height: 96px;
    }
    .footerWrapper > div {
        padding: 3px 0;
    }
    .footerWrapper > div:last-child {
        padding: 0px;
    }
    .footerWrapper > div:first-child {
        padding: 12px 0px 7px;
    }
    .countryIcons {
        justify-content: center;
    }
}