
.header {
    margin: 40px 0 20px;
    font-weight: 700;
    font-size: 20px;
}

.userName {
    font-weight: 700;
}
.time {
    color: grey;
    font-size: 14px;
}

.shareButton {
    background-color: rgb(201, 199, 197); 
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    padding: 8px 36px;
    font-weight: 700;
    cursor: unset;
    transition: all linear .2s;
}
.shareButton.acitve {
    background-color: rgb(129 70 131);
    cursor: pointer;
}

.inputWrapper {
    display: flex;
    flex-direction: column-reverse;
    border: #f1f1f1 solid 1px;
}
.inputToolbar {
    margin-bottom: 0 !important;
    background-color: rgb(247, 247, 247) !important;
    position: relative;
}
.inputToolbarInline > div {
    margin-right: 0;
    margin-left: 0;
}
.inputToolbarInline > div:last-child {
    margin-right: 10px;
}

.inputToolbarList > div {
    margin-right: 0;
    margin-left: 0;
}
.inputToolbarList > div:last-child {
    margin-right: 10px;
}

.inputToolbarPopupEmoji  {
    bottom: 35px;
    right: 5px;
    top: unset !important; 
    left: unset !important;
}

.inputEditor {
    line-height: 1.2;
}
.inputEditor > div > div > div {
    padding: 15px;
}
.inputEditor > div > div > div > div > div > div {
    margin: 5px !important;
}

.inputComment {
    line-height: 1.2;
}
.inputComment > div > div > div > div > div > div {
    margin: 5px 0 !important;
}

.inputComment span {
    background-color: transparent !important;
}
.inputComment {
    background-color: transparent;
    border:unset;
}
.inputComment.editing {
    background-color: white;
    border: solid 1px #f2f0f1;
}

.comments {
    margin-top: 10px
}

.commentWrapper {
    margin: 5px 0;
}
.subcommentWrapper {
    margin: 5px 0 5px 40px;
}
.comment {
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
    padding: 13px 39px;
    border-bottom: 3px solid #d3d1cf;
    border-right: 3px solid #d3d1cf;
        border-radius: 33px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    /* border-bottom-left-radius: 0; */

    background: #fffbfc;
}

.commentTitle {
    margin-bottom: 3px;
}

.interactionSection {
    margin-top: 7px;
}

.likeSection {
    display: inline-block;
}
.editingSection {
    display: inline-block;
}

.likeButton {
    /* padding-left: 0; */
    margin-left: -6px;
}

.reactionIcon {
    font-size: 16px
}
.dislikeButton {
    margin: 0 0px 0 3px
}

.cancelSpan {
    font-weight: 700;
}

.commentToolbar {
    box-sizing: border-box;
    width: max-content;
    max-width: 100%;
    transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    /* overflow: hidden; */
    pointer-events: none;
    margin: 0;
    padding: 0;
    background: #fffbfc;
}
.commentToolbar.active {
    margin-top: 10px;
    opacity: 1;
    height: auto;
    pointer-events: unset;
    padding: 6px 5px 0;
    margin-bottom: 5px;
}
.saveButton {
    background-color: rgb(201, 199, 197); 
    padding: 8px 30px;
    margin-bottom: 6px;
    margin-left: 10px;
    color: #fff;
    font-weight: 700;
    transition: all linear .2s;
    cursor: unset;
} 
.saveButton.acitve {
    background-color: rgb(129 70 131);
    cursor: pointer;
}

@media (max-width: 562px) {
    .likeSection {
        display: block;
        text-align: center;
    }
    .editingSection {
        display: block;
        text-align: center;
        margin-top: 5px;
    }
    .collapseButton {
        margin-top: 5px;
    }
    .interactionSection {
        text-align: center;
    }
    .subcommentWrapper {
        margin-left: 25px;
    }
}
@media (max-width: 485px) {
    .shareButton {
        position: unset;
        right: unset;
        top: unset;
        transform: unset;
        margin-left: 10px;
        margin-bottom: 5px
    }
}