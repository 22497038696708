.authorizationModal {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100svh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;
}
.authorizationModal.active {
  opacity: 1;
  pointer-events: all;
}

.authorizationModalContent {
  max-width: 320px;
  max-height: 80vh;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background-color: #fff;
  transition: all linear 0.2s;
  transform: scale(0);
  text-align: left;
}
.authorizationModalContent.active {
  transform: scale(1);
}

.authorizationModalContent input:focus {
  display: inline-block;
  border-color: #c21818;
  outline: 0 none;
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.nameWrapper {
  display: flex;
  justify-content: space-between;
}
.nameWrapper div:last-child {
  margin-left: 10px;
}
.passwordWrapper {
  display: flex;
  justify-content: space-between;
}
.passwordWrapper div:last-child {
  margin-left: 10px;
  width: 100%;
}
.passwordWrapper div:first-child {
  margin-left: 0;
  width: 100%;
}

.input {
  width: 100%;
  height: 2em;
  border-radius: 5px;
  border: 1px solid rgb(172, 172, 172);
  font-size: 12px;
  font-weight: 700;
  font-family: Tinos, serif;
  text-align: center;
  transition: all linear 0.3s;
  padding: 0;
  margin-top: 5px;
}
.invalidInput {
  border: 2px solid rgb(255, 0, 0);
}

.errorText {
  color: rgb(255, 0, 0);
  font-weight: 700;
  font-size: 12px;
  padding: 6px 0;
}

.button {
  cursor: pointer;
  display: block;
  border-radius: 5px;
  margin: 20px auto 15px;
  background-color: #666;
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 130 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23220000' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23440000' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23660000' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23880000' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23A00' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: absolute;
  background-size: cover;
  /* background by SVGBackgrounds.com */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 300 1600 800'%3E%3Cg %3E%3Cpolygon fill='%2301091b' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23011235' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23021a50' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%2302236a' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23032C85' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  color: white;
  padding: 6px 25px;
  font-size: 18px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  width: 100%;
  height: 50px;
  transition: all linear 0.2s;
}
.button:hover {
  opacity: 0.7;
}

.icon {
  font-size: 26px;
}
.googltButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  background: #db4c3e;
  border: 1px solid #db4c3e;
  color: #fff;
  width: 100%;
  height: 50px;
  padding: 6px 25px;
  line-height: 36px;
  text-align: left;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  transition: all linear 0.2s;
}
.googltButton:hover {
  background: #bd4033;
  border-color: #bd4033;
  border: 1 px solid #db4c3e;
}
