header {
    height: 96px;
    filter: none;
    transition: all linear .4s;
}
.navbar {
    opacity: .95;
    box-shadow: 0 0 6px rgb(110, 110, 110);
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 100;
}

.tabsWrapper {
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tabsWrapper.AM > .menu > ul > li {
    margin-left: 17px;
}
.tabsWrapper.AM > .menu > ul > li:first-child {
    margin-left: 0px;
}
.tabsWrapper.AM > .menu > ul > li > a {
    font-size: 16px;
}

.tabsWrapper.AM > form > input {
    font-size: 14px;
}

.tabsWrapper.AM > .authorization > button {
    font-size: 16px;
    padding: 4px 13px;
}

.form {
    position: relative;
}

.form input {
    width: 250px;
    height: 2em;
    border: 2px solid rgb(223, 223, 223);
    font-family: 'Tinos', serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    transition: all linear .3s;
}
.form input:focus {
    border-color: #C21818;
    outline: 0 none;
}

.form > svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: grey;
    font-size: 18px;
}

.search {
    display: none;
    font-size: 25px;
}

.menu > ul {
    display: flex;
}

.menu > ul li {
    margin-left: 25px; 
}
.menu > ul li:first-child{
    margin-left: 0px;
}
.menu > ul li a{
    font-weight: 700;
    padding-top: 30%;
    padding-bottom: 30%;
    transition: all linear .3s;
}
.menu > ul li a:hover{
    color: #C21818;
}

.burger {
    width: 36px;
    height: 25px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    display: none;
}
.burger:before {
    content: '';
    position: absolute;
    top: 0;    
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: all linear .3s;
}
.burger:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: all linear .3s;
}
.burger span {
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 44%;
    background-color: #000;
    transition: all linear .3s;
}
.burgerActive:before {
    transform: rotate(45deg);
    top: 45%;
}
.burgerActive:after {
    transform: rotate(-45deg);
    bottom: 45%;
}
.burgerActive span {
    background-color: #fff;
    left: -40px;
}

.active {
    color: #C21818;
}

.fa {
    display: inline-block;
    border-radius: 3333px;
    /* padding: 7px; */
    margin: 1.5px;
    font-size: 27px;
    transition: all linear .3s;
}
.fa:hover {
    color: #1c1c1c;
}
.fa > svg{
    display: block;
}

.faVk {
    /* background: #3B5998; */
    color: #2a5885;
}
.faYoutube {
    /* background: #bb0000; */
    color: #bb0000;
}
.faTelegram {
    /* background: #032c85; */
    color: #000;
}
.faFacebook {
    /* background: #032c85; */
    color: #395693;
}

.authorization {
    display: flex;
    justify-content: space-between;
}
.authorization button {
    background-color: transparent;
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    transition: all linear .2s;
    border: 2px solid #1c1c1c;
    padding: 5px 15px;
    border-radius: 10px;
    color: black;
}
.authorization button:hover {
    color:#fff;
    background-color: #1c1c1c;
}

.login {
    margin-left: 4px;
}

.burgerLinks {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.burgerLinks > a {
    font-size: 30px !important;
    padding-right: 3vh !important;
    padding-left: 3vh !important;
}


@media (max-width: 1500px) {
    header {
        height: 76px;
    }
    .tabsWrapper {
        height: 76px;
    }
    .form input {
        height: 2em;
    }
    .dropDownMenu > ul {
        top: 76px;
    }
}

@media (max-width: 1240px) {
    .form input {
        width: 230px;
    }
}

@media (max-width: 1199px) {
    .menu > ul li {
        margin-left: 25px; 
    }
    .menu > ul li:first-child{
        margin-left: 0px;
    }
    .tabsWrapper.AM .links {
        display: none;
    }
    .tabsWrapper.RU .links {
        display: none;
    }
    .fa {
        margin: 3px;
    }
    .authorization button {
        padding: 4px 12px;
    }
}

@media (max-width: 991px) {
    .form input {
        width: 200px;
    }
    .links {
        display: none;
    }
    .menu > ul li {
        margin-left: 18px; 
    }
    /* Languages styles */
    .tabsWrapper.AM .menu > ul > li {
        margin-left: 14px; 
    }
    .tabsWrapper.AM .menu > ul > li > a {
        font-size: 14px;
    }
    .tabsWrapper.AM > form > input {
        width: 140px;
        height: 1.6em;
        font-size: 14px;
    }
    .tabsWrapper.AM > form > input::placeholder {
        color: transparent;
    }
    .tabsWrapper.AM > .authorization > button {
        font-size: 14px;
        padding: 4px 7px;
    }
    .tabsWrapper.RU .menu > ul > li {
        margin-left: 14px; 
    }
    .tabsWrapper.RU .menu > ul > li > a {
        font-size: 14px;
    }
    .tabsWrapper.RU > form > input {
        width: 140px;
        height: 1.6em;
        font-size: 14px;
    }
    .tabsWrapper.RU > form > input::placeholder {
        color: transparent;
    }
    .tabsWrapper.RU > .authorization > button {
        font-size: 14px;
        padding: 4px 7px;
    }
}

@media (max-width: 767px) {
    header {
        height: 66px;
    }
    .tabsWrapper {
        height: 66px;
    }

    .menu > ul {
        box-shadow: 0 0 6px rgb(189 189 189);
        width: 100%;
        z-index: 1;
        top: 66px;
        position: absolute;
        left: -100%;
        top: 66px;
        transition: all linear .1s;
        display: inline-block;
        overflow: scroll;
        height: 100svh;
        background-color: #fff;
    }
    .menu > ul > li {
        margin-left: 0 !important; 
        position: relative;
    }
    .menu > ul li a {
        display: block;
        border-bottom: 1px solid rgb(236, 236, 236);
        font-size: 20px;
        padding: 3vh 0;
        padding-left: 58px;
        background-color: rgb(253, 253, 253);
    }

    .menuIcon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform:translateY(-50%);
        font-size: 20px;
    }

    .dropDownMenu > ul {   
        left: 0;
    }
    .form {
        flex-basis: 50%;
    }
    .form input {
        width: 100% !important;
        height: 34px !important;
        flex-basis: 80%;
    }
    .form input::placeholder {
        color:rgb(110, 110, 110) !important;
    }
    .burger {
        display: block;
    }

    .authorization button {
        font-size: 18px;
    }   
}

@media (max-width: 478px) {
    .search {
        display: block;
    }
    .form {
        position: absolute;
        top: -80px;
        left: 50%;
        width: 60%;
        transform: translateX(-50%);
        transition: all linear .4s;
    }
    .activeForm {
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
    }
    .activeForm input {
        background-color: rgb(255, 240, 240);
    }
}

@media (max-width: 374px) {
    
}