.readerModal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    height: 100svh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
}
.readerModal.active {
    opacity: 1;
    pointer-events: all;
}

.readerModalContent {
    height: 115px;
    width: 250px;
    position: relative;
    transition: all linear 0.2s;
    transform: scale(0);
    padding: 14px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: #fff;
    margin: 0 auto;
}
.readerModalContent.active {
    transform: scale(1);
}

.readerModalContent > div {
    font-weight: 900;
    margin: auto;
    text-align: center;
}
.figure {
    transition: all linear .2s;
    cursor: pointer;
    border: 2px solid red;
    opacity: 0;
    pointer-events: none;
}
.figure.active {
    border: 2px solid #000;
    opacity: 1;
    pointer-events: all;
}
.figure:hover {
    background-color: #000;
    transform: scale(1.2);
}
.figure > a,
.figure > div {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
}
.figure > a > svg,
.figure > div > svg{
    font-size: 20px;
}
.figure > a > svg:hover,
.figure > div > svg:hover {
    color: #fff;
    transform: scale(1.2)
}
.figure > a > svg > path,
.figure > div > svg > path {
    pointer-events: none;
}

.circle {
    width: 22px;
    height: 22px;
    border-radius: 1000px;
}
.square {
    width: 20px;
    height: 20px;
}
.rhombus {
    width: 20px;
    height: 20px;
    transform: rotate(45deg)
}

.uploadButton {
    height: 31px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    border: 2px #000 solid;
    cursor: pointer;
}

.commonFlag {
    width: 50%;
    box-shadow: 0px -5px 9px 3px rgb(99 99 99 / 20%);
}