.readerModalContent {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100svh;
  width: 100vw;
  transition: all linear 0.2s;
  transform: scale(0);
}
.readerModalContent.active {
  transform: scale(1);
}

/* .readerModalContent > div > div{
    background-color: #000 !important;
}
.readerModalContent > div > div > div > div{
    inset: 50px 0 20px !important;
}

.readerModalContent > div > div > button{
    z-index: 110 !important;
    height: 45px !important;
}
.readerModalContent > div > div > button > span:first-child{
    top:26% !important;
}
.readerModalContent > div > div > button > span:last-child{
    bottom:25% !important;
    top:unset !important;
}
.readerModalContent > div > div > button > span:last-child:after{
    content: '';
    width:100%;
    height: 2px;
    background-color: #ccc;
    position: absolute;
    top: -11px;
    left: 0;
}

.readerModalContent > div > div > div{
}
.readerModalContent > div > div > div iframe{
} */