.item {
    opacity: .9;
    position: relative;
    display: flex;
    max-width: 920px;
    height: 220px;
    background-color: #3E0000; /*#747474;*/
    border: 8px solid #fff;
    color: #fff;
    box-shadow: 0 1px 3px rgb(110, 110, 110);
    border-radius: 33px;
    border-top-left-radius: 999px;
    position: relative;
    margin: 0 auto 50px;
    transition: all linear .2s;
}
.item:hover {
    opacity: 1;
}
.secondItem {
    background-color: #7D0000; /*#2c2c2c;*/
}

.item > a > img {
    transition: all linear .2s;
    height: 190px;
    margin: 7px 0 0 10px;
    border: 1px solid #fff;
}
.item > a > img:hover {
    transform: scale(1.1);
}

.textContent {
    padding: 20px;
    overflow: hidden;    
}

.textContent > a {
    position: absolute;
    top: 30px; 
    right: 77px;
    font-family: 'Tinos', serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    border: 1px solid #fff;
    padding: 10px 30px;
    transition: all linear .3s;
}
.textContent > a:hover {
    background-color: #fff;
    color: #1b1b1b;
}
.textContent > button {
    background: transparent;
    position: absolute;
    top: 30px; 
    right: 20px;
    cursor: pointer;
}
.textContent > button > svg {
    color: #fff;
    font-size: 30px;
    transition: all linear .3s;
}
.textContent > button > svg:hover {
    color:rgb(250, 203, 203)
}
.bookmark {
    position: relative;
}
.bookmarkToAdd {
    position: absolute;
    left: 6px;
    opacity: 0;
}
.bookmarkToAdd:hover {
    opacity: 1;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
    max-width: 60%;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
}
.title a {
    color: #fff;
    transition: all linear .2s;
}
.title a:hover {
    opacity: .8;
}

.author{
    max-width: 60%;
    font-size: 16px;
    color: #ADAA9A;
    letter-spacing: 1px;
    margin: 8px 0 10px;
}

.ratingWrapper {
    display: flex;
}
.ratingWrapper > p {
    margin-left: 10px;
    font-size: 18px;
    line-height: 13px;
}

.text {
    font-family: 'Tinos';
    font-size: 16px;
    letter-spacing: 1.1px;
    line-height: 1.4;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

@media (max-width: 1600px) {
    .title {
        font-size: 22px;
    }
}
@media (max-width: 991px) {
    .title {
        max-width: 45%;
        font-size: 18px;
    }
    .author {
        font-size: 14px;
    }
    .textContent > a {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .title {
        max-width: 90%;
    }
}

@media (max-width: 767px) {
    .textContent {
        padding: 14px;
    }
    .textContent > a {
        display: none;
    }
    .textContent > button {
        top: 20px; 
        right: 13px;
    }
    .bookmarkToAdd {
        display: none;
    }
    .item:first-child {
        margin-top: 20px;
    }
    .item {
        border-radius: 20px;
    }
    .item > a > img {
        position: relative;
        top: 50%;
        margin-top: 0;
        margin-left: 14px;
        transform: translateY(-50%);
    }
    .item > a > img:hover {
        transform: translateY(-50%);
    }
    .text {
        -webkit-line-clamp: 5;
        line-height: 1.24;
    }
}
@media (max-width: 684px) {
    .author{
        margin: 5px 0 7px;
    }
    .textContent > div {
        height: 23px;
    }
    .text {
        font-size: 15px;
        line-height: 1.322;
        -webkit-line-clamp: 6;
    }
}
@media (max-width: 545px) {
    .item {
       height: 170px;
       margin-bottom: 25px;
    }
    .item > a > img {
        height: 150px;
        margin-left: 10px;
    }
    .title {
        font-size: 16px;
    }
    .author {
        font-size: 12px;
    }
    .text {
        font-size: 14px;
        -webkit-line-clamp: 4;
    }
    .textContent {
        padding-left: 12px;
    }
    .textContent > button > svg {
        font-size: 27px;
    }
}
@media (max-width: 478px) {
    .item {
        height: 160px;
    }
    .item > a > img {
         height: 140px;
         margin-left: 10px;
    }
    .textContent {
        padding: 10px;
    }
    .text {
        line-height: 1.29;
        font-size: 12px;
        -webkit-line-clamp: 5;
    }
    .title {
        font-size: 14px;
        max-width: 80%;
    }
    .author {
        font-size: 10px;
        margin: 3px 0 5px;
    }
}